import { checkError, prepareApiParams } from 'erp-skeleton-library';
import api from '@/plugins/api';
import { partyRolesType } from '@/plugins/enums';

const state = {
  allCustomers: [],
};

const mutations = {
  LIST(state, payload) {
    state.allCustomers = payload;
  },
};

const actions = {
  list({ commit }, payload) {
    let query = prepareApiParams(payload, 'id,name', {
      'roles{type}': partyRolesType.TYPE_CUSTOMER,
    });

    return new Promise((resolve, reject) => {
      api.get('parties?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default ({
  namespaced: true,
  state,
  mutations,
  actions,
});
