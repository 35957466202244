const state = {
  tableLoading: false,
  allReports: [],
  headers: [],
};

const mutations = {
  LIST(state, payload) {
    state.allReports = payload;
    state.tableLoading = false;
  },
};

const actions = {
  list({ commit }, payload) {

  },
};

export default ({
  namespaced: true,
  state,
  mutations,
  actions,
});
