import Vue from 'vue';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import '@/plugins/vee-validate';
import '@/plugins/vue-the-mask';
import TVue from '@/plugins/tvue';

Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
  TVue,
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');
