import Vue from 'vue';
import Router from 'vue-router';
import { userType } from '@/plugins/enums';
import store from '@/store/store';
import { baseStore, hideFabButtons, navigationGuard, verifyDebug } from 'erp-skeleton-library';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            userType: [
              userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER,
              userType.TYPE_SELLER,
            ],
          },
          component: () => import('./views/Dashboard.vue'),
        },
        {
          path: '/parties/customers',
          name: 'customers',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/customers/Customer.vue'),
        },
        {
          path: '/parties/customers/:id',
          name: 'customers-info',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/customers/CustomerInfo.vue'),
          props: true,
        },
        {
          path: '/parties/suppliers',
          name: 'suppliers',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/suppliers/Supplier.vue'),
        },
        {
          path: '/parties/suppliers/:id',
          name: 'suppliers-info',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/suppliers/SupplierInfo.vue'),
          props: true,
        },
        {
          path: '/parties/sellers',
          name: 'sellers',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/sellers/Seller.vue'),
        },
        {
          path: '/parties/sellers/:id',
          name: 'sellers-info',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/sellers/SellerInfo.vue'),
          props: true,
        },
        {
          path: '/products/register',
          name: 'products-register',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/products/register/Product.vue'),
        },
        {
          path: '/products/register/:id',
          name: 'products-register-info',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/products/register/ProductInfo.vue'),
          props: true,
        },
        {
          path: '/products/stock',
          name: 'products-stock',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/products/stock/ProductStock.vue'),
        },
        {
          path: '/products/sell',
          name: 'products-sell',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/products/sell/ProductSell.vue'),
          props: true,
        },
        {
          path: '/products/customer-ranking',
          name: 'products-customer-ranking',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/products/customer-ranking/ProductCustomerRanking.vue'),
          props: true,
        },
        {
          path: '/products/special',
          name: 'products-special',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/products/special/SpecialProduct.vue'),
        },
        {
          path: '/products/special/register',
          name: 'products-special-register',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/products/special/SpecialProductRegister.vue'),
        },
        {
          path: '/products/special/register/:id',
          name: 'products-special-edit',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/products/special/SpecialProductRegister.vue'),
        },
        {
          path: '/products/special/daily/:id',
          name: 'products-special-daily-sale',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/products/special/SpecialProductDailySale.vue'),
        },
        {
          path: '/documents/sale',
          name: 'documents-sale',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/documents/sale/DocumentSale.vue'),
        },
        {
          path: '/documents/sale/:id',
          name: 'documents-sale-info',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/documents/sale/DocumentSaleInfo.vue'),
          props: true,
        },
        {
          path: '/documents/purchase',
          name: 'documents-purchase',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/documents/purchase/DocumentPurchase.vue'),
        },
        {
          path: '/documents/purchase/:id',
          name: 'documents-purchase-info',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/documents/purchase/DocumentPurchaseInfo.vue'),
          props: true,
        },
        {
          path: '/invoices/purchase',
          name: 'invoices-purchase',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/invoices/purchase/InvoicePurchase.vue'),
        },
        {
          path: '/invoices/purchase/:id',
          name: 'invoices-purchase-info',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/invoices/purchase/InvoicePurchaseInfo.vue'),
          props: true,
        },
        {
          path: '/invoices/sale',
          name: 'invoices-sale',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/invoices/sale/InvoiceSale.vue'),
        },
        {
          path: '/invoices/sale/:id',
          name: 'invoices-sale-info',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
          component: () => import('./views/invoices/sale/InvoiceSaleInfo.vue'),
          props: true,
        },
        {
          path: '/invoices/costs',
          name: 'invoices-costs',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/invoices/ImportCost.vue'),
          props: true,
        },
        {
          path: '/profile',
          name: 'profile',
          meta: {
            userType: [
              userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT,
              userType.TYPE_SELLER,
            ],
          },
          component: () => import('./views/Profile.vue'),
        },
      ],
    },
    {
      path: '/login',
      alias: '*',
      name: 'login',
      component: () => import('./views/Index.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  hideFabButtons();
  verifyDebug();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/login',
      });
    } else if (!sessionStorage.getItem('tokenIsValid')) {
      store.dispatch('users/verifyUserStatus', baseStore.state.appUser.id)
           .then(response => {
             if (response.active) {
               sessionStorage.setItem('tokenIsValid', 'true');
               next();
             } else {
               alert('Esse usuário está inativo. Tente novamente e se o erro persistir, entre em contato conosco.');
               store.commit('baseStore/SIGN_OUT');
               router.push('/dashboard');
             }
           })
           .catch(() => {
             store.commit('baseStore/SIGN_OUT');
             if (from.name !== 'login') {
               alert('Sessão expirada! Faça o login novamente');
               router.push('/login');
             } else {
               alert('Houve um problema durante a conexão. Tente novamente e se o erro persistir, entre em contato conosco.');
             }
           });
    } else {
      (navigationGuard(to.meta, baseStore.state.appUser)) ? next() : next({ path: '/dashboard' });
    }
  } else if (!store.getters.loggedIn) {
    next();
  } else {
    next({ path: '/dashboard' });
  }
});

export default router;
