import api from '@/plugins/api';
import { checkError, cloneDeep, isNullOrEmpty, parseAddressToApp, prepareApiParams } from 'erp-skeleton-library';

const template = {
  id: 0,
  name: null,
  taxId: null,
  addresses: [
    {
      address: {
        zipCode: null,
        city: {},
        state: {},
        country: {},
        address: null,
        number: null,
      },
    },
  ],
  emails: [
    {
      email: null,
    },
  ],
  phones: [
    {
      fullNumber: null,
    },
  ],
};

const state = {
  tableLoading: false,
  allParties: [],
  rolesChosen: [],
  registerParty: cloneDeep(template),
  headers: [
    {
      text: 'Nome',
      align: 'left',
      value: 'name',
      sortable: true,
    },
    {
      text: 'Documento',
      align: 'left',
      value: 'taxId',
      width: '200px',
      sortable: false,
    },
    {
      text: 'Email',
      align: 'left',
      value: 'emails',
      sortable: false,
    },
    {
      text: 'Telefone',
      align: 'left',
      value: 'phones',
      sortable: false,
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allParties = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    state.rolesChosen = [];

    if (!isNullOrEmpty(payload)) {
      for (let role of payload.roles) {
        state.rolesChosen.push(role.type);
      }
      payload.addresses = (!isNullOrEmpty(payload.addresses))
                          ? parseAddressToApp(payload)
                          : cloneDeep(template.addresses);
      payload.phones = (payload.phones) ?? cloneDeep(template.phones);
      payload.emails = (payload.emails) ?? cloneDeep(template.emails);
      state.registerParty = cloneDeep(payload);
    } else {
      state.registerParty = cloneDeep(template);
    }
  },
};

const actions = {
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload.apiParams, 'id,name,taxId,phones,emails,addresses,accountBalance,ordersBalance,lastPurchase', payload.filterFields);

    return new Promise((resolve, reject) => {
      api.get('parties?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  listCustomers({ commit }, { filterFields, apiParams, partyId }) {
    let query = prepareApiParams(apiParams, null, filterFields);

    return new Promise((resolve, reject) => {
      api.get('parties/' + partyId + '/customers?' + new URLSearchParams(query).toString())
         .then((response) => {
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('parties/' + payload.id)
         .then(response => {
           commit('SET', response.data);
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('parties/' + payload.id, payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('parties/' + id)
         .then((response) => {
           resolve(response);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default ({
  namespaced: true,
  state,
  mutations,
  actions,
});
