import api from '@/plugins/api';
import { checkError, cloneDeep, isNullOrEmpty, prepareApiParams } from 'erp-skeleton-library';

const template = {
  id: 0,
  name: null,
  items: [],
};

const state = {
  tableLoading: false,
  allSpecialSpecialProducts: [],
  registerSpecialProducts: cloneDeep(template),
  headers: [
    {
      text: 'Nome',
      align: 'left',
      value: 'name',
      sortable: true,
    },
    {
      text: 'Quantidade de produtos',
      align: 'right',
      value: 'quantity',
      sortable: false,
    },
    {
      text: '',
      align: 'right',
      value: 'actions',
      sortable: false,
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allSpecialSpecialProducts = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (isNullOrEmpty(payload)) {
      state.registerSpecialProducts = cloneDeep(template);
    } else {
      state.registerSpecialProducts = cloneDeep(payload);
    }
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('products/special-groups', payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id,name,items');

    return new Promise((resolve, reject) => {
      api.get('products/special-groups?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('products/special-groups/' + id)
         .then((response) => {
           commit('SET', response.data);
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  readDailySales({ commit }, payload) {
    let query = prepareApiParams({}, null, payload.filterFields);

    return new Promise((resolve, reject) => {
      api.get('products/special-groups/' + payload.id + '/sales?' + new URLSearchParams(query).toString())
         .then((response) => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('products/special-groups/' + payload.id, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('products/special-groups/' + id)
         .then((response) => {
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
