import api from '@/plugins/api';
import { checkError, prepareApiParams } from 'erp-skeleton-library';
import {apportionmentType} from "@/plugins/enums";

const state = {
    allProductsCostCalculation: [],
    tableLoading: false,
    headers: [
        { text: 'Ordem', value: 'order', align: 'left', sortable: false },
        { text: 'Código', value: 'product[sapCode]', align: 'left', sortable: false },
        { text: 'Nome', value: 'product[name]', align: 'left', sortable: false },
        { text: 'Quantidade', value: 'quantity', align: 'left', sortable: false },
        { text: 'Valor US$', value: 'priceUs', align: 'rigth', sortable: false },
        { text: 'Valor R$', value: 'priceBrl', align: 'rigth', sortable: false},
        { text: 'Preço lista', value: 'productSalePriceList', align: 'rigth', sortable: false},
        { text: 'Estoque', value: 'productStock', align: 'left', sortable: false},
        { text: '', value: 'data-table-expand' },
    ],
    apportionmentTypes: [
        {id: apportionmentType.TYPE_COST, label: 'Custo'},
        {id: apportionmentType.TYPE_VOLUME, label: 'Volume'},
        {id: apportionmentType.TYPE_WEIGHT, label: 'Peso'},
    ],
    allProductsCostCalculationDownload: [],
};

const mutations = {
    LIST(state, payload) {
        state.allProductsCostCalculation = payload.items;
        state.tableLoading = false;
    },
    DOWNLOAD(state, payload) {
        state.allProductsCostCalculationDownload = payload;
        state.tableLoading = false;
    },
    CLEAR(state) {
        state.allProductsCostCalculation = [];
        state.tableLoading = false;
    }
};

const actions = {
    list({ commit }, payload) {
        state.tableLoading = true;
        let query = prepareApiParams(null, null, payload.filterFields);

        return new Promise((resolve, reject) => {
            api.get('/invoices/' + payload.invoice + '/products-costs?' + new URLSearchParams(query).toString())
                .then((response) => {
                    commit('LIST', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    state.tableLoading = false;
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    },
    downloadExcel({ commit }, payload) {
        let query = prepareApiParams(null, null, payload.filterFields);

        return new Promise((resolve, reject) => {
            api.get('/invoices/' + payload.invoice + '/products-costs?' + new URLSearchParams(query).toString())
              .then((response) => {
                  commit('DOWNLOAD', response.data);
                  resolve(response.data);
              })
              .catch((error) => {
                  checkError(error.response.status);
                  reject(error.response);
              })
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
