import api from '@/plugins/api';
import { checkError, isNullOrEmpty, prepareApiParams } from 'erp-skeleton-library';
import { allMonths } from '@/plugins/enums';

const state = {
  tableLoading: false,
  allProductsSell: [],
  headers: [
    {
      text: 'Mês',
      value: 'label',
      align: 'left',
      sortable: false,
    },
  ],
};

const formatCorrectPayload = (payload) => {
  let correctArray = [];
  let currentMonthData = {};
  let currentMonthLabel = null;

  for (let i = 1; i <= 12; i++) {
    currentMonthData = {};
    currentMonthData = setObjectAttributes(payload, i);
    currentMonthLabel = allMonths.find(item => item.id === i).label;
    correctArray.push({ label: currentMonthLabel, ...currentMonthData });
  }

  correctArray = setYearInfoAttributes(correctArray, payload);

  return correctArray;
};

const setYearInfoAttributes = (correctArray, payload) => {
  let infoTotal = {};
  let infoAverage = {};

  for (let year in payload) {
    infoTotal[year + '-purchases'] = payload[year]['totalPurchases'];
    infoTotal[year + '-sales'] = payload[year]['totalSales'];

    infoAverage[year + '-purchases'] = payload[year]['averagePurchases'];
    infoAverage[year + '-sales'] = payload[year]['averageSales'];
  }

  correctArray.push({ label: 'Total', ...infoTotal });
  correctArray.push({ label: 'Média', ...infoAverage });

  return correctArray;
};

const setObjectAttributes = (payload, currentMonth) => {
  let currentMonthData = {};

  for (let year in payload) {
    currentMonthData[year + '-purchases'] = payload[year]['months'][currentMonth]['purchases'];
    currentMonthData[year + '-sales'] = payload[year]['months'][currentMonth]['sales'];
  }

  return currentMonthData;
};

const mutations = {
  LIST(state, payload) {
    state.headers = [
      {
        text: 'Mês',
        value: 'label',
        align: 'left',
        sortable: false,
      },
    ];

    if (!isNullOrEmpty(payload[0].accumulated)) {
      for (let year in payload[0].accumulated) {
        state.headers.push({
          text: year + ' Compra',
          value: year + '-purchases',
          align: 'right',
          sortable: false,
        });

        state.headers.push({
          text: year + ' Venda',
          value: year + '-sales',
          align: 'right',
          sortable: false,
        });
      }

      state.allProductsSell = formatCorrectPayload(payload[0].accumulated);
    }
    state.tableLoading = false;
  },
  CLEAR_HEADERS() {
    state.headers = [
      {
        text: 'Mês',
        value: 'label',
        align: 'left',
        sortable: false,
      },
    ];
  },
};

const actions = {
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload.apiParams, 'id,name,amountPaid,info{taxId},phones', payload.filterFields);

    return new Promise((resolve, reject) => {
      api.get('/products/accumulated?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data);
           resolve(response.data);
         })
         .catch(error => {
           console.log(error);
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
