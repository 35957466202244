import { userType } from '@/plugins/enums';
import api from '@/plugins/api';

export function loggedIn(state) {
  api.defaults.headers['Authorization'] = 'Bearer ' + state.baseStore.apiToken;
  return state.baseStore.apiToken !== null;
}

export function getUserType(state) {
  if (state.baseStore.appUser && state.baseStore.appUser.type !== userType.TYPE_USER) {
    return ({
      userType: parseInt(state.baseStore.appUser.type),
    });
  } else {
    return ({
      userType: parseInt(state.baseStore.appUser.type),
      tenantUserType: parseInt(state.baseStore.appUser.type),
    });
  }
}
