import {checkError, cloneDeep, isNullOrEmpty, prepareApiParams} from "erp-skeleton-library";
import api from "@/plugins/api";

const template = {
    id: 0,
    code: null,
    name: null,
}

const state = {
    tableLoading: false,
    registerImportCosts: cloneDeep(template),
    allImportCosts: [],
    headers: [
        {
            text: 'Código',
            value: 'code',
            align: 'left',
            sortable: false
        },
        {
            text: 'Nome',
            value: 'name',
            align: 'left',
            sortable: false
        }
    ],
}

const mutations = {
    LIST(state, payload) {
        state.allImportCosts = cloneDeep(payload);
        state.tableLoading = false;
    },
    SET(state, payload) {
        if (payload !== undefined) {
            state.registerImportCosts = cloneDeep(payload);
        } else {
            state.registerImportCosts = cloneDeep(template);
        }
    }
}

const actions = {
    list({commit}, payload) {
        state.tableLoading = true;
        let query = {};

        if (payload !== undefined) {
            query = prepareApiParams(payload.apiParams, payload.apiFields);
        } else {
            query = prepareApiParams(null, 'id,name,code');
        }

        return new Promise((resolve, reject) => {
            api.get('/products/import-costs?' + new URLSearchParams(query).toString())
                .then((response) => {
                    commit('LIST', response.data.entities);
                    resolve(response.data);
                })
                .catch(error => {
                    state.tableLoading = false;
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    },
    create({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.post('/products/import-costs', payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    },
    update({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.put('/products/import-costs/' + payload.id, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    }
}

export default ({
    namespaced: true,
    state,
    mutations,
    actions,
});