import {checkError, cloneDeep, prepareApiParams} from "erp-skeleton-library";
import api from "@/plugins/api";

const template = {
    id: 0,
    invoice: null,
    cost: null,
    value: null,
}

const state = {
    tableLoading: false,
    registerInvoiceCosts: cloneDeep(template),
    allInvoiceCosts: [],
    headers: [
        {
            text: 'Nome',
            value: 'name',
            align: 'left',
            sortable: false
        },
        {
            text: 'Valor',
            value: 'value',
            align: 'left',
            sortable: false
        },
    ]
}

const mutations = {
    LIST(state, payload) {
        state.allInvoiceCosts = cloneDeep(payload);
    },
    SET(state, payload) {
        if (payload !== undefined) {
            state.registerInvoiceCosts = cloneDeep(payload);
        } else {
            state.registerInvoiceCosts = cloneDeep(template);
        }
    }
}

const actions = {
    list({commit}, payload) {
        state.tableLoading = true;
        let query = prepareApiParams(payload.apiParams, payload.apiFields);

        return new Promise((resolve, reject) => {
            api.get('/invoices/' + payload.id + '/costs?' + new URLSearchParams(query).toString())
                .then((response) => {
                    commit('LIST', response.data.entities);
                    resolve(response.data);
                })
                .catch(error => {
                    checkError(error.response.status);
                    reject(error.response);
                })
                .finally(() => state.tableLoading = false);
        });
    },
    create({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.post('/invoices/' + payload.invoice + '/costs', payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    },
    update({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.put('/invoices/' + payload.invoice + '/costs')
            .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    checkError(error.response.status);
                    reject(error.response);
                })
        })
    },
    delete({commit}, payload) {
        let invoiceId = location.pathname.split('/')[3];

        return new Promise((resolve, reject) => {
            api.delete('/invoices/' + invoiceId + '/costs/' + payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    checkError(error.response.status);
                    reject(error.response);
                })
        })
    },
}

export default ({
    namespaced: true,
    state,
    mutations,
    actions,
})