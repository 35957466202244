import axios from 'axios';
import { isNullOrEmpty, handle401Error } from 'erp-skeleton-library';

let lastRequestWasAuth = false;
let lastRequest = {
  route: '',
  action: '',
  data: '',
};
const api = axios.create({
  baseURL: location.protocol + '//' + location.hostname.replace('app.', 'api.') + '/v1',
  headers: {
    'timezone-offset': (new Date().getTimezoneOffset() / 60) * -1 + ':00',
  },
});

api.interceptors.request.use(
  (request) => {
    if (request.url === 'auth') {
      lastRequestWasAuth = true;
    } else {
      lastRequestWasAuth = false;
      setLastRequestInformation(request);
    }

    if (sessionStorage.getItem('apiDebug')) {
      let beforeParam = (request.url.includes('?')) ? '&' : '?';
      request.url += beforeParam + 'XDEBUG_SESSION_START=' + sessionStorage.getItem('apiDebug');
    }

    return request;
  },
  (error) => console.log(error),
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message.startsWith('Network')) {
      error = {
        response: {
          status: 500,
          data: {
            message: error.message,
          },
        },
      };
    } else if (error.message.endsWith('401') && !lastRequestWasAuth) {
      return handle401Error()
        .then(() => makeLastRequestAgain());
    }

    return Promise.reject(error);
  },
);

function setLastRequestInformation(request) {
  lastRequest.route = request.url;
  lastRequest.action = request.method;

  if (!isNullOrEmpty(request.data)) {
    lastRequest.data = request.data;
    return;
  }

  lastRequest.data = null;
}

function makeLastRequestAgain() {
  return new Promise((resolve, reject) => {
    api[lastRequest.action](lastRequest.route, lastRequest.data)
      .then(resolve)
      .catch(reject);
  });
}

export default api;
