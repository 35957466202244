import api from '@/plugins/api';
import { checkError, cloneDeep, isNullOrEmpty, parseAddressToApp, prepareApiParams } from 'erp-skeleton-library';
import { documentStatus } from '@/plugins/enums';

const template = {
  id: 0,
  addresses: [
    {
      address: {
        zipCode: null,
        city: {},
        state: {},
        country: {},
        address: null,
        number: null,
      },
    },
  ],
};

const state = {
  tableLoading: false,
  allDocuments: [],
  documentInfo: cloneDeep(template),
  headersPurchase: [
    {
      text: 'Código',
      align: 'left',
      value: 'sapCode',
      sortable: true,
    },
    {
      text: 'Número',
      align: 'left',
      value: 'number',
      sortable: true,
    },
    {
      text: 'Fornecedor',
      align: 'left',
      value: 'party',
      sortable: true,
    },
    {
      text: 'País',
      align: 'left',
      value: 'country',
      sortable: false,
    },
    {
      text: 'Estado',
      align: 'left',
      value: 'state',
      sortable: false,
    },
    {
      text: 'Cidade',
      align: 'left',
      value: 'city',
      sortable: false,
    },
    {
      text: 'Data',
      align: 'left',
      value: 'date',
      sortable: true,
    },
    {
      text: 'Referência',
      align: 'left',
      value: 'reference',
      sortable: false,
    },
    {
      text: 'Status',
      align: 'left',
      value: 'status',
      sortable: true,
    },
  ],
  headersSale: [
    {
      text: 'Código',
      align: 'left',
      value: 'sapCode',
      sortable: true,
    },
    {
      text: 'Número',
      align: 'left',
      value: 'number',
      sortable: true,
    },
    {
      text: 'Cliente',
      align: 'left',
      value: 'party',
      sortable: true,
    },
    {
      text: 'Estado',
      align: 'left',
      value: 'state',
      sortable: false,
    },
    {
      text: 'Cidade',
      align: 'left',
      value: 'city',
      sortable: false,
    },
    {
      text: 'Data',
      align: 'left',
      value: 'date',
      sortable: true,
    },
    {
      text: 'Referência',
      align: 'left',
      value: 'reference',
      sortable: false,
    },
    {
      text: 'Status',
      align: 'left',
      value: 'status',
      sortable: true,
    },
  ],
  documentsStatus: [
    {
      id: documentStatus.TYPE_REGISTERED,
      label: 'REGISTRADO',
      color: 'blue',
    },
    {
      id: documentStatus.TYPE_CANCELED,
      label: 'CANCELADO',
      color: 'red',
    },
    {
      id: documentStatus.TYPE_FINISHED,
      label: 'FINALIZADO',
      color: 'green',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allDocuments = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (!isNullOrEmpty(payload)) {
      payload.addresses = (!isNullOrEmpty(payload.addresses))
                          ? parseAddressToApp(payload)
                          : cloneDeep(template.addresses);
      delete payload.address;
      state.documentInfo = cloneDeep(payload);
      return;
    }

    state.documentInfo = cloneDeep(template);
  },
};

const actions = {
  list({ commit }, payload) {
    state.tableLoading = true;

    if (!isNullOrEmpty(payload.filterFields.periodStart) && !isNullOrEmpty(payload.filterFields.periodEnd)) {
      payload.filterFields.date = '{operator:BETWEEN|value:' + payload.filterFields.periodStart + ',' + payload.filterFields.periodEnd + ' 23:59:59}';
    } else if (!isNullOrEmpty(payload.filterFields.periodStart)) {
      let today = new Date().toISOString().substring(0, 10);
      payload.filterFields.date = '{operator:BETWEEN|value:' + payload.filterFields.periodStart + ',' + today + ' 23:59:59}';
    }
    delete payload.filterFields.periodStart;
    delete payload.filterFields.periodEnd;

    let query = prepareApiParams(
      payload.apiParams,
      'id,sapCode,number,date,reference,status,party,total,discount,taxes,address,items{quantity,product}',
      payload.filterFields,
    );

    return new Promise((resolve, reject) => {
      api.get('documents?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      api.get('documents/' + id)
         .then((response) => {
           commit('SET', response.data);
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  readDashboard({ commit }, payload) {
    let query = prepareApiParams(null, 'purchase, sell', payload.filterFields);

    return new Promise((resolve, reject) => {
      api.get('dashboard/invoices?' + new URLSearchParams(query).toString())
         .then((response) => {
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('documents/' + id)
         .then((response) => {
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default ({
  namespaced: true,
  state,
  mutations,
  actions,
});
