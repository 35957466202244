import Vue from 'vue';
import TVue from 'erp-skeleton-library';
import api from '@/plugins/api';
import router from '@/router';
import store from '@/store/store';
import * as enums from '@/plugins/enums';

TVue.api = api;
TVue.router = router;
TVue.store = store;
TVue.enums = enums;

Vue.use(TVue);

export default TVue;
