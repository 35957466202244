import api from '@/plugins/api';
import { checkError, prepareApiParams } from 'erp-skeleton-library';

const state = {
  tableLoading: false,
  allProductStocks: [],
  headers: [
    {
      text: 'Armazém',
      value: 'name',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Quantidade',
      value: 'quantity',
      align: 'right',
      sortable: false,
    },
    {
      text: 'Vendas confirmadas',
      value: 'saleConfirmed',
      align: 'right',
      sortable: false,
    },
    {
      text: 'Compras confirmadas',
      value: 'purchaseConfirmed',
      align: 'right',
      sortable: false,
    },
    {
      text: 'Pedido',
      value: 'reserved',
      align: 'right',
      sortable: false,
    },
    {
      text: 'Saldo',
      value: 'balance',
      align: 'right',
      sortable: false,
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allProductStocks = payload;
    state.tableLoading = false;
  },
};

const actions = {
  list({ commit }, { apiParams, filterFields }) {
    state.tableLoading = true;
    let query = prepareApiParams(apiParams, 'id,product{id,name,category},quantity,location{id}', filterFields);

    return new Promise((resolve, reject) => {
      api.get('products/stocks?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, { product, apiParams }) {
    let query = prepareApiParams(apiParams, 'id,quantity,stocks,location{id}');

    return new Promise((resolve, reject) => {
      api.get('products/' + product + '/stocks?' + new URLSearchParams(query).toString())
         .then((response) => {
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
