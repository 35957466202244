import api from '../../plugins/api';
import {
  baseStore,
  checkError,
  cloneDeep,
  isNullOrEmpty,
  parseAddressToApp,
  prepareApiParams,
} from 'erp-skeleton-library';

let template = {
  id: 0,
  name: null,
  email: null,
  addresses: [
    {
      address: {
        zipCode: null,
        city: {},
        state: {},
        country: {},
        neighborhood: null,
        number: null,
        address: null,
        complement: null,
      },
    },
  ],
  phones: [ {} ],
  info: {
    birthday: null,
    profession: null,
    taxId: null,
  },
};

const state = {
  registerProfile: cloneDeep(template),
};

const mutations = {
  VERIFY_CONFIG(state, payload) {
    if (isNullOrEmpty(baseStore.state.appUser.config)) {
      state.isFirstTimeDashboardTour = true;
      state.isDashboardTourOn = true;
    } else if (!baseStore.state.appUser.config[payload]) {
      state.isDashboardTourOn = true;
    } else {
      state.isFirstTimeDashboardTour = false;
    }
  },
  SET(state, payload) {
    if (!isNullOrEmpty(payload)) {
      payload.phones = (!!payload.phones) ? payload.phones : [ { fullNumber: '' } ];
      if (!isNullOrEmpty(payload.addresses)) {
        payload.addresses = parseAddressToApp(payload);
      } else {
        payload.addresses = cloneDeep(template.addresses);
      }

      state.registerProfile = Object.assign({}, payload);
    } else {
      state.registerProfile = cloneDeep(template);
    }
  },
};

const actions = {
  list({ commit }, payload) {
    let query = prepareApiParams(null, 'id,email', payload);

    return new Promise((resolve, reject) => {
      api.get('users?' + new URLSearchParams(query).toString())
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  create({ commit }, payload) {
    if (!baseStore.state.appUser) {
      delete api.defaults.headers['Authorization'];
    }

    return new Promise((resolve, reject) => {
      api.post('users', payload)
         .then(response => {
           resolve(response);
         })
         .catch(error => {
           console.log(error);
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  verifyUserStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('users/' + payload)
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  readUserProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('users/' + payload)
         .then(response => {
           commit('SET', response.data);
           resolve(response);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('users/' + payload)
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('users/' + payload.id, payload)
         .then(response => {
           baseStore.state.appUser.name = payload.name;
           baseStore.state.appUser.email = payload.email;
           commit('SET', payload);
           resolve(response);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  delete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.delete('users/' + payload)
         .then(response => {
           resolve(response);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

