import { partyRolesType, userType } from '@/plugins/enums';

export default {
  menusDashboard: [
    {
      id: '1',
      icon: 'mdiViewDashboard',
      name: 'Dashboard',
      href: '/dashboard',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
      },
    },
    {
      id: '2',
      icon: 'mdiAccount',
      name: 'Pessoas',
      href: '/parties',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
      },
      children: [
        {
          id: '3',
          name: 'Clientes',
          href: '/parties/customers',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
        },
        {
          id: '4',
          name: 'Fornecedores',
          href: '/parties/suppliers',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '5',
          name: 'Vendedores',
          href: '/parties/sellers',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
      ],
    },
    {
      id: '6',
      icon: 'mdiTools',
      name: 'Produtos',
      href: '/products',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
      },
      children: [
        {
          id: '7',
          name: 'Cadastros',
          href: '/products/register',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
        },
        {
          id: '8',
          name: 'Estoque',
          href: '/products/stock',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
          },
        },
        {
          id: '9',
          name: 'Venda acumulada',
          href: '/products/sell',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '10',
          name: 'Ranking de cliente',
          href: '/products/customer-ranking',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '11',
          name: 'Itens principais',
          href: '/products/special',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
      ],
    },
    {
      id: '12',
      icon: 'mdiCashRegister',
      name: 'Pedidos de venda',
      href: '/documents/sale',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
      },
    },
    {
      id: '13',
      icon: 'mdiCart',
      name: 'Pedidos de compra',
      href: '/documents/purchase',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
      },
    },
    {
      id: '14',
      icon: 'mdiReceiptTextPlus',
      name: 'Notas de entrada',
      href: '/invoices/purchase',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
      },
    },
    {
      id: '15',
      icon: 'mdiReceiptTextMinus',
      name: 'Notas de saída',
      href: '/invoices/sale',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_SELLER ],
      },
    },
    {
      id: '16',
      icon: 'mdiCurrencyUsd',
      name: 'Custo de importação',
      href: '/invoices/costs',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
      },
    },
  ],
  partyRoles: [
    {
      label: 'Empresa',
      type: partyRolesType.TYPE_COMPANY,
    },
    {
      label: 'Colaborador',
      type: partyRolesType.TYPE_EMPLOYEE,
    },
    {
      label: 'Cliente',
      type: partyRolesType.TYPE_CUSTOMER,
    },
    {
      label: 'Fornecedor',
      type: partyRolesType.TYPE_VENDOR,
    },
  ],
};
