import api from '@/plugins/api';
import { checkError, prepareApiParams } from 'erp-skeleton-library';

const state = {
  allProductsCategories: [],
};

const mutations = {
  LIST(state, payload) {
    state.allProductsCategories = payload;
  },
};

const actions = {
  list({ commit }, payload) {
    let query = prepareApiParams(payload, 'id,name');

    return new Promise((resolve, reject) => {
      api.get('products/categories?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
