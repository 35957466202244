export const userType = {
  TYPE_SYSTEM: 1,
  TYPE_ADMIN: 2,
  TYPE_SUPPORT: 3,
  TYPE_USER: 4,
  TYPE_SELLER: 5,
};

export const usersCanAccessApp = [
  userType.TYPE_ADMIN,
  userType.TYPE_SUPPORT,
  userType.TYPE_USER,
];

export const partyRolesType = {
  TYPE_COMPANY: 1,
  TYPE_EMPLOYEE: 2,
  TYPE_CUSTOMER: 3,
  TYPE_VENDOR: 4,
};

export const documentStatus = {
  TYPE_REGISTERED: 1,
  TYPE_CANCELED: 2,
  TYPE_FINISHED: 3,
};

export const documentTypes = {
  TYPE_SALE: 1,
  TYPE_PURCHASE: 2,
};

export const invoicesTypes = {
  TYPE_SALE: 1,
  TYPE_PURCHASE: 2,
};

export const invoicesStatus = {
  TYPE_REGISTERED: 1,
  TYPE_CANCELED: 2,
  TYPE_FINISHED: 3,
};

export const apportionmentType = {
  TYPE_COST: 1,
  TYPE_VOLUME: 2,
  TYPE_WEIGHT: 3,
};

export const months = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12
}

export const weekDays = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
}

export const allMonths = [
  {
    label: 'Janeiro',
    id: months.JANUARY
  },
  {
    label: 'Fevereiro',
    id: months.FEBRUARY
  },
  {
    label: 'Março',
    id: months.MARCH
  },
  {
    label: 'Abril',
    id: months.APRIL
  },
  {
    label: 'Maio',
    id: months.MAY
  },
  {
    label: 'Junho',
    id: months.JUNE
  },
  {
    label: 'Julho',
    id: months.JULY
  },
  {
    label: 'Agosto',
    id: months.AUGUST
  },
  {
    label: 'Setembro',
    id: months.SEPTEMBER
  },
  {
    label: 'Outubro',
    id: months.OCTOBER
  },
  {
    label: 'Novembro',
    id: months.NOVEMBER
  },
  {
    label: 'Dezembro',
    id: months.DECEMBER
  },
]

export const allWeekDays = [
  {
    label: 'Domingo',
    id: weekDays.SUNDAY
  },
  {
    label: 'Segunda',
    id: weekDays.MONDAY
  },
  {
    label: 'Terça',
    id: weekDays.TUESDAY
  },
  {
    label: 'Quarta',
    id: weekDays.WEDNESDAY
  },
  {
    label: 'Quinta',
    id: weekDays.THURSDAY
  },
  {
    label: 'Sexta',
    id: weekDays.FRIDAY
  },
  {
    label: 'Sábado',
    id: weekDays.SATURDAY
  },
]
