import api from '@/plugins/api';
import { checkError, cloneDeep, isNullOrEmpty, parseAddressToApp, prepareApiParams } from 'erp-skeleton-library';
import { invoicesStatus } from '@/plugins/enums';

const template = {
  id: 0,
  addresses: [
    {
      address: {
        zipCode: null,
        city: {},
        state: {},
        country: {},
        address: null,
        number: null,
      },
    },
  ],
};

const state = {
  tableLoading: false,
  allInvoices: [],
  invoiceInfo: cloneDeep(template),
  headers: [
    {
      text: 'Código',
      align: 'left',
      value: 'sapCode',
      sortable: true,
    },
    {
      text: 'Nome',
      align: 'left',
      value: 'name',
      sortable: true,
    },
    {
      text: 'Data emissão',
      align: 'left',
      value: 'date',
      sortable: true,
    },
    {
      text: 'Número',
      align: 'left',
      value: 'number',
      sortable: true,
    },
    {
      text: 'Valor nota',
      align: 'right',
      value: 'total',
      sortable: false,
    },
    {
      text: 'IPI',
      align: 'right',
      value: 'ipiTotal',
      sortable: false,
    },
    {
      text: 'ICMS',
      align: 'right',
      value: 'icmsTotal',
      sortable: false,
    },
    {
      text: 'ICMS-ST',
      align: 'right',
      value: 'icmsStTotal',
      sortable: false,
    },
  ],
  invoiceStatus: [
    {
      id: invoicesStatus.TYPE_REGISTERED,
      label: 'REGISTRADO',
      color: 'blue',
    },
    {
      id: invoicesStatus.TYPE_CANCELED,
      label: 'CANCELADO',
      color: 'red',
    },
    {
      id: invoicesStatus.TYPE_FINISHED,
      label: 'FINALIZADO',
      color: 'green',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allInvoices = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (!isNullOrEmpty(payload)) {
      payload.addresses = (!isNullOrEmpty(payload.addresses)) ? parseAddressToApp(payload) : cloneDeep(template.addresses);
      delete payload.address;
      state.invoiceInfo = cloneDeep(payload);
      return;
    }

    state.invoiceInfo = cloneDeep(template);
  },
};

const actions = {
  list({ commit }, payload) {
    state.tableLoading = true;

    if (!isNullOrEmpty(payload.filterFields.periodStart) && !isNullOrEmpty(payload.filterFields.periodEnd)) {
      payload.filterFields.date = '{operator:BETWEEN|value:' + payload.filterFields.periodStart + ',' + payload.filterFields.periodEnd + ' 23:59:59}';
    } else if (!isNullOrEmpty(payload.filterFields.periodStart)) {
      let today = new Date().toISOString().substring(0, 10);
      payload.filterFields.date = '{operator:BETWEEN|value:' + payload.filterFields.periodStart + ',' + today + ' 23:59:59}';
    }
    delete payload.filterFields.periodStart;
    delete payload.filterFields.periodEnd;

    let query = prepareApiParams(payload.apiParams, payload.apiFields, payload.filterFields);

    return new Promise((resolve, reject) => {
      api.get('invoices?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('invoices/' + payload.id)
         .then((response) => {
           commit('SET', response.data);
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default ({
  namespaced: true,
  state,
  mutations,
  actions,
});
