import api from '@/plugins/api';
import { checkError, cloneDeep, isNullOrEmpty, prepareApiParams } from 'erp-skeleton-library';

const template = {
  id: 0,
  name: null,
  type: null,
  sku: null,
  sapCode: null,
  barCode: null,
  description: null,
  measurementUnit: null,
  category: null,
  manufacturer: {},
  prices: [
    { sale: null },
  ],
  vehicle: {
    manufacturer: null,
    model: null,
    equivalentModel: null,
    year: null,
    skus: [
      { type: null, sku: null },
    ],
  },
};

const state = {
  tableLoading: false,
  allProducts: [],
  allProductsAutocomplete: [],
  informationProduct: cloneDeep(template),
  headers: [
    {
      text: 'Código',
      align: 'left',
      value: 'sapCode',
      sortable: true,
    },
    {
      text: 'Nome',
      align: 'left',
      value: 'name',
      sortable: true,
    },
    {
      text: 'Fabricante',
      align: 'left',
      value: 'manufacturer',
      sortable: false,
    },
    {
      text: 'Categoria',
      align: 'left',
      value: 'category',
      sortable: false,
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allProducts = payload;
    state.tableLoading = false;
  },
  LIST_AUTOCOMPLETE(state, payload) {
    for (let product of payload) {
      if (!state.allProductsAutocomplete.some(e => e.id === product.id)) {
        state.allProductsAutocomplete.push(product);
      }
    }
  },
  SET(state, payload) {
    if (!isNullOrEmpty(payload)) {
      if (isNullOrEmpty(payload.vehicle)) {
        payload.vehicle = cloneDeep(template.vehicle);
      }
      state.informationProduct = cloneDeep(payload);
    }
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('products', payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  autocomplete({ commit }, payload) {
    let query = prepareApiParams(payload, 'id,name,brand,sapCode,vehicle{model}');

    return new Promise((resolve, reject) => {
      api.get('products?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST_AUTOCOMPLETE', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           console.log(error);
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id,name,category,manufacturer,brand,sapCode,description,vehicle{model}');

    return new Promise((resolve, reject) => {
      api.get('products?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      api.get('products/' + id)
         .then((response) => {
           commit('SET', response.data);
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  readAveragePrice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('products/' + payload.product + '/cost')
         .then((response) => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('products/' + payload.id, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('products/' + id)
         .then((response) => {
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
