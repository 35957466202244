import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as getters from './getters';
import parties from '@/store/modules/parties/parties';
import customers from '@/store/modules/parties/customers';
import sellers from '@/store/modules/parties/sellers';
import suppliers from '@/store/modules/parties/suppliers';
import invoices from '@/store/modules/invoices';
import products from '@/store/modules/products/products';
import documents from '@/store/modules/documents';
import locations from '@/store/modules/locations';
import reports from '@/store/modules/reports';
import productsSell from '@/store/modules/products/products-sell';
import productsPurchase from '@/store/modules/products/products-purchase';
import productsCategories from '@/store/modules/products/products-categories';
import productsStock from '@/store/modules/products/products-stock';
import productsCustomerRanking from '@/store/modules/products/products-customer-ranking';
import productsCostCalculation from "@/store/modules/products/products-cost-calculation";
import specialProducts from '@/store/modules/products/products-special';
import users from '@/store/modules/users';
import { baseStore } from 'erp-skeleton-library';
import importCosts from "@/store/modules/import-costs";
import invoiceCosts from "@/store/modules/invoice-costs";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    parties,
    customers,
    sellers,
    suppliers,
    invoices,
    documents,
    locations,
    reports,
    users,
    baseStore,
    products,
    productsCategories,
    productsSell,
    productsPurchase,
    productsCustomerRanking,
    productsCostCalculation,
    specialProducts,
    productsStock,
    importCosts,
    invoiceCosts,
  },
  state,
  getters,
});

export default store;
