import api from '@/plugins/api';
import { checkError, prepareApiParams } from 'erp-skeleton-library';

const state = {
  tableLoading: false,
  allProductsPurchase: [],
  headers: [
    {
      text: 'Código',
      value: 'number',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Data de lançamento',
      value: 'date',
      align: 'left',
      sortable: true,
    },
    {
      text: 'Quantidade',
      value: 'quantity',
      align: 'right',
      sortable: false,
    },
    {
      text: 'Preço',
      value: 'price',
      align: 'right',
      sortable: false,
    },
    {
      text: 'Valor adquirido',
      value: 'total',
      align: 'right',
      sortable: false,
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allProductsPurchase = payload;
    state.tableLoading = false;
  },
};

const actions = {
  list({ commit }, { productId, apiParams, filterFields }) {
    state.tableLoading = true;
    let query = prepareApiParams(apiParams, 'id,number,date,price,quantity,total', filterFields);

    return new Promise((resolve, reject) => {
      api.get('products/' + productId + '/purchases?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
