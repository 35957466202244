import api from '@/plugins/api';
import { checkError, prepareApiParams } from 'erp-skeleton-library';

const state = {
  allProductCustomerRanking: [],
  tableLoading: false,
  headers: [
    { text: 'Cliente', value: 'party', align: 'left', sortable: false },
    { text: 'Cidade', value: 'city', align: 'left', sortable: false },
    { text: 'Estado', value: 'state', align: 'left', sortable: false },
    { text: 'Quantidade', value: 'quantity', align: 'right', sortable: false },
    { text: 'Última compra', value: 'date', align: 'right', sortable: false },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allProductCustomerRanking = payload;
    state.tableLoading = false;
  },
};

const actions = {
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload.apiParams, 'id,party,quantity,date,state,city', payload.filterFields);

    if (query['periodStart']) {
      query['periodStart'] = query['periodStart'] + ' 00:00:00';
    }

    if (query['periodEnd']) {
      query['periodEnd'] = query['periodEnd'] + ' 23:59:59';
    }

    return new Promise((resolve, reject) => {
      api.get('products/ranking?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
