import api from '@/plugins/api';
import { checkError, prepareApiParams } from 'erp-skeleton-library';

const state = {
  allLocations: [],
  tableLoading: false,
};

const mutations = {
  LIST(state, payload) {
    state.allLocations = payload;
    state.tableLoading = false;
  },
};

const actions = {
  list({ commit }, payload) {
    return new Promise((resolve, reject) => {
      state.tableLoading = true;
      let query = prepareApiParams(payload, 'id, name');

      api.get('locations?' + new URLSearchParams(query).toString())
         .then((response) => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default ({
  namespaced: true,
  state,
  mutations,
  actions,
});
